import React from "react"
import styled from "@emotion/styled"

import imgFG from "../images/fg-texture.jpg"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretRight, faCaretLeft } from "@fortawesome/free-solid-svg-icons"

const MIN_INDEX = 0
const MAX_INDEX = 12

const Category = styled.section`
  border-radius: 3px;
  background-image: url(${imgFG});
  margin: 0 10px;
  margin-bottom: 40px;
  padding: 30px;
  background-color: ;
  @media (min-width: 768px) {
    margin: auto;
    margin-bottom: 40px;
    max-width: 660px;
  }
  @media (min-width: 1024px) {
    margin-bottom: 80px;
    padding: 50px;
  }
`
const TitleWrap = styled.div` 
  text-align: center;
  position: relative;
  padding-bottom: 0px;
  margin-bottom: 1rem;
  border-bottom: 1px solid #00ABD4;
`
const Title = styled.h2` 
  padding-bottom: 10px;
  margin-bottom: 2px;
  border-bottom: 1px solid #00ABD4;
  text-transform: Capitalize;
`
const Item = styled.div` 
  display: flex;
  margin-bottom: 20px;
`
const Left = styled.div`
  flex: 3;
  padding-right: 10px;
  max-width: 400px;
  }
  @media (min-width: 420px) {
    padding-right: 30px;
  }
  @media (min-width: 768px) {
    flex:4;
  }
  @media (min-width: 1024px) {
    flex:5;
  }
`
const Right = styled.div`
  padding-right: 10px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: right;
  text-align: right;
`
const Name = styled.div`
  font-family: "Oswald", sans-serif;
  color: #fff;
`
const Description = styled.div`
  color: #AAA;
`
const Price = styled.div`
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  color: #fff;
`
const ArrowLeft = styled.button`
  background-color: transparent;
  border: none;
  color: #00abd4;
  padding: 10px 20px;
`
const ArrowRight = styled.button`
  background-color: transparent;
  border: none;
  color: #00abd4;
  padding: 10px 20px;
`

const MenuItem = React.forwardRef((props, ref) => {
  const menu = props.menu
  const { indexState, handleIndexChange } = props
  let elements = []
  let name
  let items
  let arrows = []
  if(menu) {
    name = menu.name
    items = menu.items
    items.map((e) => {
      const name = e.name ? e.name : ''
      const description = e.description ? e.description : ''
      const price = e.price ? e.price : ''
      elements.push(<Item key={name}><Left><Name>{name}</Name><Description>{description}</Description></Left><Right><Price>{price}</Price></Right></Item>)
    })
  }
  return (
    <Category ref={ref}>
      <TitleWrap>
        <Title>
          { props.show_left &&
            <ArrowLeft onClick={() => {
              const indexChange = indexState-1
              if(indexChange < MIN_INDEX)
                handleIndexChange(MIN_INDEX)
              else 
                handleIndexChange(indexChange)
            }}>
              <FontAwesomeIcon icon={faCaretLeft}/>
            </ArrowLeft> }
          {name}
          { props.show_right &&
            <ArrowRight onClick={()=> {
              const indexChange = indexState+1
              if(indexChange > MAX_INDEX)
                handleIndexChange(MAX_INDEX)
              else 
                handleIndexChange(indexChange)
            }}>
              <FontAwesomeIcon icon={faCaretRight}/>
            </ArrowRight> }
        </Title>
      </TitleWrap>
      {elements}
    </Category>
  )
})

export default MenuItem
