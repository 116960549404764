import React, { useState } from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { LocaleContext } from "../components/layout"
import locales from "../../config/i18n"

import SEO from "../components/seo"
import BG from "../components/image-responsive"
import LogoOysterDive from "../components/logo-oyster-dive"
import LogoLaval from "../components/logo-laval"
import LogoDix30 from "../components/image-logo-dix30"
import LogoHQWhite from "../components/logo-hq-white"
import LogoBrasserieWhite from "../components/logo-brasserie-white"
import LogoCateringWhite from "../components/logo-catering-white"
import LogoTraiteur from "../components/logo-traiteur-white"
import LogoMarketWhite from "../components/logo-market-white"
import LogoMarche from "../components/logo-marche-white"
import LogoFoodTruckWhite from "../components/logo-food-truck-white"
import LogoCamionNourriture from "../components/logo-camion-nourriture"
import LogoLucysLemonade from "../components/image-lucys-lemonade-white"
import Contact from "../components/contact"
import MenuItem from "../components/menu-item"
import SwipeableViews from  'react-swipeable-views';

import useTranslations from "../components/use-translations"

import gbStyles from "./index.module.css"
import img from "../images/bg-texture-wide.jpg"
import imgFG from "../images/fg-texture.jpg"
import imgBG from "../images/bg-texture.jpg"
import imgUber from "../images/logo-uber-eats.png"

import {
  getKeys,
  getMenu,
} from "../data/menu_dix30"

import { 
  INDEX_PAGEKEYS_MENU,
  INDEX_PAGEKEYS_CONTACT,
} from "../data/assets"

import {
  starters,
  mains,
  sides,
  desserts,
  kids,
} from "../data/menu-items"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope, faCaretRight, faCaretDown, faCaretUp, faPhone } from "@fortawesome/free-solid-svg-icons"

const imageTable = {
  logoMonkland: () => (<LogoOysterDive />),
  logoLaval: () => (<LogoLaval />),
  logoHQ: () => (<LogoHQWhite />),
  logoBrasserie: () => (<LogoBrasserieWhite />),
  logoLemonadeTruck: () => (<LogoLucysLemonade />),

  logoCatering: (locale) => (locales[locale].default ? <LogoTraiteur /> : <LogoCateringWhite />),
  logoMarket: (locale) => (locales[locale].default ? <LogoMarche /> : <LogoMarketWhite />),
  logoFoodtruck: (locale) => (locales[locale].default ? <LogoCamionNourriture /> : <LogoFoodTruckWhite />)

}

const Section = styled.section`
  background-image: url(${img});
  background-color: #0e0f11;
  margin: 10px;
  max-width: 1000px;
  padding: 20px;
  border-radius: 4px;
  @media (min-width: 1024px) {
    margin: auto;
    margin-bottom: 80px;
  }
  @media (min-width: 1600px) {
    margin-bottom: 120px;
  }
`
const BWOut = styled.div`
  border: 1px solid #06aad4;
  border-radius: 2px;
  padding: 1px;
`
const BWIn = styled.div`
  border: 1px solid #06aad4;
  padding: 60px 40px;
  h2 {
    margin-bottom: 3rem;
  }
  ul {
    list-style: none;
    margin: 0 0 3rem 0;
  }
  .gatsby-image-wrapper {
      width: 54%;
      margin: 0px auto 60px auto;
      max-width: 200px;
  }
  @media (min-width: 1024px) {
    padding: 120px 60px;
    display: flex;
    ul {
      margin-left: 1.45rem;
      list-style: circle;
    }
    .gatsby-image-wrapper {
      margin: 60px auto;
      width: 100%;
      max-width: 200px;
    }
  }
`
const logo = styled`
`
const title = styled`
`
const content = styled`
`
const items= styled`
`
const email= styled`
`
const phone= styled`
`
const site= styled`
`
const Locations = ({locations}) => {
  const { locale } = React.useContext(LocaleContext)
  let elements = []
  locations.forEach((e)=> {

    let items = []
    let content = []

    if(e.content) {
      e.content.forEach((paragraph)=> {
        content.push(<p>{ paragraph }</p>)
      })
    }

    if(e.items) {
      e.items.forEach((item)=> {
        items.push(<li>{ item }</li>)
      })
    }

    elements.push(
    <Section>
      <BWOut>
        <BWIn>
          <div className="rightPanel">
            {e.image && imageTable[e.image].call(undefined, locale)}
          </div>
            <div className="leftPanel">
              { content }
              <ul>{ items }</ul>
              <a className={gbStyles.buttonEvents} href={`mailto:${e.email}`}>
                CONTACT &nbsp; &nbsp; 
                <FontAwesomeIcon icon={faEnvelope}/>
              </a> 
              <a className={gbStyles.buttonEvents} href={`tel:${e.tel}`}>{e.tel} &nbsp; &nbsp; 
                <FontAwesomeIcon icon={faPhone}/>
              </a>
            </div>
    
        </BWIn>
      </BWOut>
    </Section>
    )
  })
  return elements
}

const _buildMenus = (menuGroups, locale, indexState, setIndexState) => (
  menuGroups.map((group, i) => { 
    const menu = getMenu(group, locale)
    return (<MenuItem
      key={menu.name + i + Math.random()} 
      menu={menu}
      indexState={indexState}
      handleIndexChange={setIndexState}
      show_left={i !== 0 ? true : false}
      show_right={i < menuGroups.length-1 ? true : false}
      />)
  })
)


const BookingBar = styled.div`
  position: absolute;
  top: 120px;
  right: 0;
  z-index: 1;
  box-shadow: 4px 4px 2px 1px rgba(0, 0, 0, .2);
  background-image: url(${imgFG});
  @media (min-width: 480px) {
    top: 180px;
  }
  @media (min-width: 1000px) {
    top: 220px;
  }
`
const OrderOnlineBar = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  top: 180px;
  right: 0;
  z-index: 1;
  a {
    padding: 0;
  }
  img {
    margin-bottom: 0;
  }
  @media (min-width: 480px) {
    top: 240px;
  }
  @media (min-width: 1000px) {
    top: 320px;
  }
`
const Outline = styled.div`
  border-top: 1px solid #00aad4;
  border-left: 1px solid #00aad4;
  border-bottom: 1px solid #00aad4;
  margin: 4px 0 4px 4px;
`
const Inline = styled.div`
  border-top: 1px solid #00aad4;
  border-left: 1px solid #00aad4;
  border-bottom: 1px solid #00aad4;
  margin: 1px 0 1px 1px;
`
const Anchor = styled.a`
  font-size: 12px;
  padding: 10px 30px;
  text-decoration: none;
`

const AddressBar = styled.div`
  position: absolute;
  bottom: 0px;
  right: 0px;
  left: 0px;
  padding: 4px 0;
  font-size: 12px;
  color: #fff;
  background-image: url(${imgFG});
  box-shadow: 4px 4px 2px 1px rgba(0, 0, 0, 0.2);
`
const Left= styled.div`
  display: flex;
  align-items: center;
  width: 30%;
  a {
    font-size: 16px;
    padding: 0;
  }
  @media (min-width: 380px) {
    width: 45%;
  }
  @media (min-width: 480px) {
    width: 30%;
  }
`
const Right = styled.div`
  text-align: right;
  width: 70%;
  @media (min-width: 380px) {
    width: 55%;
  }
  @media (min-width: 480px) {
    width: 70%;
    div {
      display: inline;
    }
  }
`

const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #333;
  padding: 40px;
  background-image: url(${imgBG});
  @media (min-width: 1024px) {
  }
`
const Footline = styled.div`
  max-width: 600px;
  padding-right: 30px;
  @media (min-width: 1024px) {
    padding-right: 50px;
  }
`
const ButtonLink = styled.a`
  text-align: center;
  min-width: 110px;
  display: inline-block;
  background-color: #fff;
  padding: 8px 12px;
  color: #060809;
  font-family: 'Oswald';
  font-size: 16px;
  border-bottom:2px solid #333;
  border-right:2px solid #333;
  &:visited {
    color: #060809;
  }
  &:hover {
    color: #00ABD4;
    border-bottom:2px solid #00ABD4;
    border-right:2px solid #00ABD4;
  }
  @media (min-width: 1024px) {
    padding: 8px 24px;
    bottom: 20px;
    right: 10px;
  }
`
const Uber = styled.img`
  margin-right: 10px;
  margin-bottom:0;
  height: 36px;
`
const Brief = styled.p`
  text-shadow: 3px 3px 3px #111111ff;
`
const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 3rem;
`
const Box = styled.div`
  display: flex;
  flex: 1 1;
  justify-content: center;
  min-width: 400px;
  flex-direction: column;
  align-content: center;
  align-items: center;
  max-width: 400px;
  @media (min-width: 1024px) {
    margin: 1rem;
  }
  @media (min-width: 1400px) {
    margin: 2rem;
  }
`

const IndexPage = () => {
  const [indexState, setIndexState] = useState(0)
  const { 
    description,
    call_to_action,
    book_online,
    rawbar,
    steaks,
    special_events_page_title,
    special_events_page_content,
    footer_headline,
    donate
  } = useTranslations()
  const { locale, page_links } = React.useContext(LocaleContext)
  const giftcardUrl = locale === 'en' ? '' : 'fr/'

  const lang = locale.includes('en') ? 'en-ca' : 'fr-ca'

  const menuGroups = getKeys()
  const menus = _buildMenus(menuGroups, locale, indexState, setIndexState)

  return (
    <>
      <SEO 
        lang={lang}
        title={ special_events_page_title } 
        description={description}
      />

    { /*
      <div className="hero">
        <BG/>
        <div className="heroPanel">
          <h1>{ special_events_page_title }</h1>
          <Brief>{ special_events_page_content[0] }</Brief>
          
        </div>
        <BookingBar>
          <Outline>
            <Inline>
              <Anchor href={`https://www.bookenda.com/restaurant-booker?businessName=luciles-dix30${bookingUrlTail}`} rel="noopener noreferrer">
                { book_online + ' ' } &nbsp; 
                <FontAwesomeIcon icon={faCaretRight}/>
              </Anchor>
            </Inline>
          </Outline>
        </BookingBar>
        <OrderOnlineBar>
          <Uber src={imgUber} alt="orderonline" />
    <a href="https://www.ubereats.com/ca/montreal/food-delivery/lucilles-dix30/RbO8vPs2TwysKkR0h4EYHA/?utm_source=google&utm_medium=organic&utm_campaign=place-action-link" rel="noopener noreferrer" target="_blank"><img height="48px" src="https://ubr.to/order-online-white" alt="orderonline" /></a>
        </OrderOnlineBar>
        <AddressBar>
          <div style={{ borderTop: `1px solid #00aad4`, borderBottom: `1px solid #00aad4`}}>
            <div style={{ borderTop: `1px solid #00aad4`, borderBottom: `1px solid #00aad4`, margin: `1px 0`, padding: `4px 10px`, display: `flex`}}>
                <Left><a href="tel:(450) 656-2465">(450) 656-2465</a></Left>
                <Right>
                  <div>9310 Boul. Leduc, </div>
                  <div>Brossard, QC, J4Y 0B3</div>
                </Right>
            </div>
          </div>
        </AddressBar>
      </div>

      <div ref={page_links[INDEX_PAGEKEYS_MENU].ref}>
        <SwipeableViews index={indexState} onChangeIndex={setIndexState} animateHeight enableMouseEvents>
          { menus }  
        </SwipeableViews>
      </div>
        */}

      <div ref={page_links[INDEX_PAGEKEYS_CONTACT].ref}>
        <Contact/>
      </div>

    { /*
      <Footer>
        <Footline>
          { footer_headline }
        </Footline>
        <ButtonLink href={`https://www.freebeespoints.com/${giftcardUrl}PointSale/Purchase/2440`}>{donate}</ButtonLink>
      </Footer>
    */ }

    </>
  )
}

export default IndexPage
