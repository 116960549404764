import React from "react"
import styled from "@emotion/styled"
import useTranslations from "../components/use-translations"

import logo from "../images/logo-hq.png"
import img from "../images/bg-hq.png"
import imgIpad from "../images/bg-ipad-hq.png"
import imgMobile from "../images/bg-mobile-hq.png"
import imgDot from "../images/dot-50.png"
import imgFG from "../images/fg-texture.jpg"
import List from "../components/list"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMapMarkerAlt, faPhoneSquare, faEnvelope } from "@fortawesome/free-solid-svg-icons"

const Section = styled.section`
  min-height: 2430px;
  max-width: 100%;
  background-image: url(${imgMobile});
  background-size: cover;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: flex-start;
  border-top: 2px solid #2b2b2b;
  border-bottom: 2px solid #2b2b2b;
  margin:0;
  padding: 12rem 0 0 0;
  @media (min-width: 420px) {
    min-height: 2800px;
  }
  @media (min-width: 480px) {
    min-height: 3200px;
  }
  @media (min-width: 540px) {
    min-height: 2000px;
    background-image: url(${imgIpad});
  }
  @media (min-width: 738px) {
    min-height: 2800px;
    background-image: url(${imgIpad});
  }
  @media (min-width: 1200px) {
    padding: 2rem;
    align-items: center;
    min-height: 1080px;
    background-image: url(${img});
  }
`
const Wrapper = styled.div`
  border-radius: 4px;
  padding: 10px;
  margin: 10px;
  background-image: url(${imgFG});
  opacity: 0.98;

  p {
    text-align: center;
  }

  @media (min-width: 420px) {
  }
  @media (min-width: 768px) {
    max-width: 600px;
    p {
      text-align: left;
    }
  }
  @media (min-width: 1024px) {
    max-width: 800px;
    opacity: 0.94;
    padding: 20px;
  }
`
const Outline = styled.div`
  border: 1px solid #06aad4;
  border-radius: 2px;
  padding: 1px;
`
const Inline = styled.div`
  border: 1px solid #06aad4;
  padding: 1rem 1rem;
  @media (min-width: 768px) {
    border: 1px solid #06aad4;
    padding: 1rem 4rem 2rem 4rem;
  }
`
const H2 = styled.h2`
  margin: 0;
  img {
    margin:0;
  }
`
const UL = styled.ul`
  font-size: 0.9rem;
`
const Frame = styled.div`
  width: 100%;
  text-align: center;
  padding-top: 1rem;
  @media (min-width: 768px) {
    display: flex;
    gap: 1rem;
  }
`
const A = styled.a`
  border-bottom: 2px solid #fff;
  display: block;
  margin:auto;
  margin-bottom: 0.6rem;
  max-width: 200px;
  font-size: 0.9rem;
  :hover {
    border-bottom: 2px solid #06aad4;
  }
  @media (min-width: 768px) {
    flex:1;
    display: inline-block;
  }
  
`

const Contact = () => {

  const { 
    contact_title,
    hours_title,
    contact_links,
    details,
    description
  } = useTranslations()

  const items = contact_links.items

  return (
    <Section>
      <Wrapper>
        <Outline>
          <Inline>
        <H2 style={{textAlign: `center`}}><img src={ logo } /></H2>

          <p>{description}</p>
          <UL>
            <li>{details[0]}</li>
            <li>{details[1]}</li>
            <li>{details[2]}</li>
            <li>{details[3]}</li>
          </UL>
    {/*

          <table>
            <tbody>
              <tr>
                <th colSpan='2'>{ hours_title }</th>
              </tr>
              <tr>
                <td>{ label_monday }</td>
                <td>{ hours_monday }</td>
              </tr>
              <tr>
                <td>{ label_tuesday }</td>
                <td>{ hours_tuesday }</td>
              </tr>
              <tr>
                <td>{ label_wednesday }</td>
                <td>{ hours_wednesday }</td>
              </tr>
              <tr>
                <td>{ label_thursday }</td>
                <td>{ hours_thursday }</td>
              </tr>
              <tr>
                <td>{ label_friday }</td>
                <td>{ hours_friday }</td>
              </tr>
              <tr>
                <td>{ label_saturday }</td>
                <td>{ hours_saturday }</td>
              </tr>
              <tr>
                <td>{ label_sunday }</td>
                <td>{ hours_sunday }</td>
              </tr>
            </tbody>
          </table>
        

        <p><a href={`https://www.bookenda.com/restaurant-booker?businessName=lucilles-oyster-dive-laval`} target="_blank" rel="noopener noreferrer"><img src={`https://www.bookenda.com/Images/badge-booknow_book_EN.png`} alt=""/></a></p>
        */ }

          <Frame>
            <A href={ items[2].url }> 
               <FontAwesomeIcon icon={faEnvelope}/>
              &nbsp; { items[2].name } 
            </A>
            <A href={ items[1].url }> 
              <FontAwesomeIcon icon={faPhoneSquare}/>
              &nbsp; { items[1].name } 
            </A>
            <br/>
          </Frame>

          </Inline>
        </Outline>
      </Wrapper>
    </Section>
  )
}

export default Contact
