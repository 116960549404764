/* 
 *
 *
 *
 *
 *
 Menu shape
   "-MATGdzTH_3RWYyjC2YC": {
      active: true,
      menuIcon: "https://firebasestorage.googleapis.com/v0/b/orderlina-single-region/o/menu-items%2FdefaultPicFood.jpg?alt=media&token=99e5c249-275e-4c8e-91ca-1d1942b8f2f2",
      menuItems: {
        "-MATEEV7TVQ5Oj7R5SHH": {
          active: true,
          description: "CROUTONS, CÂPRES, TRUFFLE, OEUF CRU",
          description_english: "CROUTONS, CAPERS, TRUFFLE, RAW EGG",
          description_french: "CROUTONS, CÂPRES, TRUFFLE, OEUF CRU",
          image: "",
          price: 15,
          quantity: 1000,
          title: "TARTARE DE BOEUF ANGUS NOIR",
          title_english: "BEEF TARTARE",
          title_french: "TARTARE DE BOEUF ANGUS NOIR"
        },
      },
      menuTitle: "ENTRÉES ",
      menuTitle_english: "STARTERS",
      menuTitle_french: "ENTRÉES "
   },
*/

import Store from "./menu_dix30.json"
const orderlinaStore = Store.orderlinaStore

export function getMenu(key, lang) {
  const menu = { name: "", items: [] }
  const menuTitleKey = lang === 'en' ? 'menuTitle_english': 'menuTitle_french'
  const descriptionKey = lang === 'en' ? 'description_english': 'description_french'
  const titleKey = lang === 'en' ? 'title_english': 'title_french'
  const clientKey = orderlinaStore.clientKey
  const menuSelect = orderlinaStore[clientKey].info.menuItemGroupsNew[key]
  const items = menuSelect.menuItems
  if(menuSelect.active) {
    menu.name = menuSelect[menuTitleKey] ? menuSelect[menuTitleKey] : menuSelect.menuTitle
    for(let e in items) {
      const item = items[e]
      const name = item[titleKey] ? item[titleKey] : item.title
      const description = item[descriptionKey] ? item[descriptionKey] : ''
      const price = item.price ? item.price : ''
      if(item.active){
        menu.items.push({name: name, description: description, price: price.toString()})
      }
    }
  }
  return menu
}

export function getKeys() {
  const clientKey = orderlinaStore.clientKey
  const menuGroups = orderlinaStore[clientKey].info.menuItemGroupsNew
  let keys = []

  for(let item in menuGroups){
    keys.push(item)
  }
  return keys
}

const shape = {
  active: "boolean",
  description_english: "string",
  description_french: "string",
  title_english:"string",
  title_french:"string",
  price: "number",
}
