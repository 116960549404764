import { Link } from "gatsby"
import React from "react"
import styled from "@emotion/styled"

const MenuLinks = styled.div`
  :& > * {
    padding-right: 10px;
  }
`
const List = ({items}) => {
  let elements = []
  items.forEach((e) => {
    if(e.local) {
      elements.push(<Link key={e.name} to={e.url}>{ e.name }</Link>)
    } else {
      elements.push(<a key={e.name} href={e.url}>{ e.name }</a>)
    }
  })
  return <MenuLinks>{elements}</MenuLinks>
}

export default List
